import React, { Component } from 'react';
import logo from './logo.png';
import './App.css';

class App extends Component {

  constructor() {
    super()
    this.state = {
      features:[
        {name:"BILLING", selected:false,text:"BILLING",price:0, wasPrice:12000, description:"Create GST sales invoice, sales order, proforma invoice, delivery challan and much more.", moreDescription:"Quickly add products to sale by typing in keywords or using a barcode scanner. Easily find customers by name or phone number. Print simple and beautiful receipts or choose to send customizable SMS and email."},
        {name:"INVENTORY",selected:false, text:"INVENTORY",price:0, wasPrice:12000, description:"Purchase Order, Purchase Entry, GRN, Purchase Return, Damage, Stock Check, Stock Update, Expense, Barcode Printing"},
        {name:"CRM - MARKETER", selected:false,text:"CRM - MARKETER",price:0, wasPrice:6000, description:"Send automatic campaigns for all important occasions. Organize your campaigns based on customer spending habits, age, gender, demographic and much more."},
        {name:"CRM - DELIGHT", selected:false,text:"CRM - DELIGHT", price:0, wasPrice:6000, description:"Delight your customers with discounts and offers. Enroll them to your membership program, give coupons, run offers, run store wallets, track appointments."},
        {name:"EXPENSE MANAGEMENT", selected:false,text:"EXPENSE MANAGEMENT",price:0, wasPrice:6000, description:"Track vendor, employee and other miscellaneous expense."},
        {name:"STAFF MANAGEMENT", selected:false,text:"STAFF MANAGEMENT", price:0, wasPrice:6000, description:"Track employee attendance and performance"},
        // {name:"CUSTOMER FEEDBACK",selected:false, text:"CUSTOMER FEEDBACK ",wasprice:0, wasPrice:2999, price:0, wasPrice:1999, description:""},
        // {name:"REFERRAL PROGRAM", selected:false,text:"REFERRAL PROGRAM", wasprice:0, wasPrice:5999, price:0, wasPrice:2999,description:""},
        {name:"LOYALTY", selected:false,text:"LOYALTY",price:0, wasPrice:20000,description:"Enroll customers to your loyalty program."},
      
      ],
      selectedFeatures:[],
      featureTotal:0,
      featureDiscountedTotal:0,
      subTotal:0,
      additionalStore:0,
      additionalStoreRate:0,
      additionalStoreDiscountPercentage:0,
      additionalStoreDiscountAmount:0,
      additionalStoreAmount:0,
      additionalCashRegister:0,
      additionalCashRegisterRate:0,
      additionalCashRegisterDiscount:0,
      additionalCashRegisterAmount:0,
      transactionalSMSLot:0,
      transactionalSMSLotRate:1500,
      transactionalSMSLotAmount:0,
      promotionalSMSLot:0,
      promotionalSMSLotRate:1500,
      promotionalSMSLotAmount:0,
      onsiteVisit:false,
      onsiteVisitCharge:0,
      nextClicked:false,
      noOfFeaturesSelected:0,
      mainStoreDiscountPercentage:0,
      mainStoreDiscountAmount:0,
      mainStoreAmount:0,
      step:1,
      paymentPlan:"Yearly",
      subTotal:0,
      taxAmount:0,
      amount:0,
      senderID:false,
      senderIDAmount:1000
     }
    this.featureSelected = this.featureSelected.bind(this)
    this.onNextClicked= this.onNextClicked.bind(this)
    this.onPaymentPlanChanged = this.onPaymentPlanChanged.bind(this)
    this.computeFeaturesAsPerPaymentPlan = this.computeFeaturesAsPerPaymentPlan.bind(this)
    this.calculateFeatureTotal= this.calculateFeatureTotal.bind(this)
    this.onBackClicked = this.onBackClicked.bind(this)
    this.computeOnsiteTraining = this.computeOnsiteTraining.bind(this)
    this.onAdditionalStoreChange= this.onAdditionalStoreChange.bind(this)
  }

  componentDidMount()
  {
      this.computeFeaturesAsPerPaymentPlan(this.state.paymentPlan)
  }

    featureSelected(index, price)
    {
      let features = this.state.features
      features[index].price = price
      features[index].selected = !features[index].selected
      this.computeFeaturesAsPerPaymentPlan(this.state.paymentPlan)
      this.calculateFeatureTotal(features)
    }

    calculateFeatureTotal(features)
    {
      let featureTotal = 0
      let featureDiscountedTotal=0
      features.filter(f=> f.selected).map(m=> {
        featureTotal= featureTotal+ m.wasPrice
        featureDiscountedTotal= featureDiscountedTotal+ m.price
      })
      this.setState({features:features,featureTotal:featureTotal, featureDiscountedTotal:featureDiscountedTotal })
    }

    onPaymentPlanChanged(evt)
    {
        this.setState({paymentPlan: evt.target.value})
        this.computeFeaturesAsPerPaymentPlan(evt.target.value)   
    }

    computeFeaturesAsPerPaymentPlan(paymentPlan)
    {
        let discountPer = 0
        let features= this.state.features
        let mainStoreDiscountPercentage = 0
        features.map(m=> {
          let price = 0 
          let priceAdditionalInfo=""
          if(paymentPlan ==="Yearly")
          {
            mainStoreDiscountPercentage = 35
            price = (m.wasPrice * 50 /100)
            priceAdditionalInfo = (Math.round((price/12 ))-1 ) + " p.m"
          }
          else if(paymentPlan==="Half Yearly")
          {
            mainStoreDiscountPercentage= 20
            price = Math.round(((Number(m.wasPrice) - (m.wasPrice * 20 /100))/2),0)-1
            priceAdditionalInfo = (price * 2) + " p.a"
          }
          else if(paymentPlan==="Quaterly")
          {
            mainStoreDiscountPercentage = 10
            price = ((Number(m.wasPrice) - (m.wasPrice * 10 /100))/4)-1
            priceAdditionalInfo = (price * 4) + " p.a"
          }
          else
          {
            mainStoreDiscountPercentage= 0 
            price = Math.round((Number(m.wasPrice)/12),0)-1
            priceAdditionalInfo = (price * 12) + " p.a"
          }
          m.price= price
          m.priceAdditionalInfo = priceAdditionalInfo
        })
        this.setState({discountPer:discountPer, features:features, mainStoreDiscountPercentage:mainStoreDiscountPercentage})
        this.calculateFeatureTotal(features)
    }

    onAdditionalStoreChange(additionalStore)
    {

      this.setState({additionalStore: additionalStore})
      this.computeOnsiteTraining(Number(additionalStore),this.state.onsiteVisit)
    }

    computeOnsiteTraining(additionalStore, onsiteVisit)
    {
        this.setState({onsiteVisitCharge:onsiteVisit ? ((1+ Number(additionalStore)) * 3000):0 })
    }

    onNextClicked()
    {
        //calculate how much discount can be given 
        if(this.state.step ===1)
        {
          let noOfFeaturesSelected = 0 
          let mainStoreDiscountPercentage =0// Number(this.state.mainStoreDiscountPercentage)
          let mainStoreDiscountAmount = 0 
          let mainStoreAmount = 0
          noOfFeaturesSelected = this.state.features.filter(f=>f.selected).length
          if(this.state.paymentPlan==="Yearly")
          {
            mainStoreDiscountPercentage=35
          }
          if(this.state.paymentPlan ==="Half Yearly")
          {
            mainStoreDiscountPercentage=20
          }
          
          if(noOfFeaturesSelected ===0)
          {
              alert('Please select one or more features')
              return
          }
          if(this.state.paymentPlan==="Yearly" || this.state.paymentPlan ==="Half Yearly")
          {
            if((noOfFeaturesSelected - 2)> 0)
            {
              mainStoreDiscountPercentage = mainStoreDiscountPercentage +  (noOfFeaturesSelected - 2) * 5
    
            }
            mainStoreDiscountAmount = Math.round((this.state.featureTotal * mainStoreDiscountPercentage)/100)
          }   
          mainStoreAmount = Number(this.state.featureTotal) - Number(mainStoreDiscountAmount)
          this.setState({noOfFeaturesSelected:noOfFeaturesSelected,
            mainStoreDiscountPercentage:mainStoreDiscountPercentage,
            mainStoreDiscountAmount:mainStoreDiscountAmount,
            mainStoreAmount:mainStoreAmount,
            nextClicked:true,
            step:2
          })
        }
       else if(this.state.step ===2)
       {
          let additionalStoreRate = this.state.featureTotal
          let additionalStoreDiscountPercentage =0
          let additionalStoreDiscountAmount = 0 
          let additionalCashRegisterRate = 2999
          let additionalCashRegisterDiscount=0
          let additionalCashRegisterAmount = 0 
          if(this.state.paymentPlan==="Monthly"){
            additionalCashRegisterAmount= Math.round((Number(this.state.additionalCashRegister) * additionalCashRegisterRate)/12)
          }
          else if (this.state.paymentPlan==="Quaterly"){
            additionalCashRegisterAmount= Math.round((Number(this.state.additionalCashRegister) * additionalCashRegisterRate )/4)
          }
          else if (this.state.paymentPlan==="Half Yearly"){
            additionalCashRegisterAmount= Math.round((Number(this.state.additionalCashRegister) * additionalCashRegisterRate )/2)
          }
          else
          {
            additionalCashRegisterAmount= Math.round(Number(this.state.additionalCashRegister) * additionalCashRegisterRate)
          }
          if(this.state.paymentPlan==="Yearly" || this.state.paymentPlan ==="Half Yearly")
          {
            if(this.state.noOfFeaturesSelected > 1 && this.state.noOfFeaturesSelected < 3)
            {
              additionalStoreDiscountPercentage = 20
              additionalStoreDiscountAmount = Math.round(((additionalStoreRate * additionalStoreDiscountPercentage)/100)) * this.state.additionalStore
              
            }
            else if(this.state.noOfFeaturesSelected > 2 && this.state.noOfFeaturesSelected < 4)
            {
              additionalStoreDiscountPercentage = 30
              additionalStoreDiscountAmount = Math.round(((additionalStoreRate * additionalStoreDiscountPercentage)/100)) * this.state.additionalStore
              
            }
            else if(this.state.noOfFeaturesSelected > 3 && this.state.noOfFeaturesSelected < 5)
            {
              additionalStoreDiscountPercentage = 40
              additionalStoreDiscountAmount = Math.round(((additionalStoreRate * additionalStoreDiscountPercentage)/100)) * this.state.additionalStore
              
            }
            else if(this.state.noOfFeaturesSelected >= 5)
            {
              additionalStoreDiscountPercentage = 50
              additionalStoreDiscountAmount = Math.round(((additionalStoreRate * additionalStoreDiscountPercentage)/100))* this.state.additionalStore
              
            }
          }
          // else if (this.state.paymentPlan ==="Half Yearly")
          // {
          //     if((additionalStoreRate /2)> 1495)
          //     {
          //       additionalStoreDiscountAmount = ((additionalStoreRate * 50)/100) * this.state.additionalStore
          //       additionalStoreDiscountPercentage = 50
          //     }
          // }
          let additionalStoreAmount =  (Number(this.state.additionalStore) *additionalStoreRate )- additionalStoreDiscountAmount
          
          let subTotal = Number(this.state.mainStoreAmount) + Number(additionalStoreAmount) + Number(this.state.transactionalSMSLotAmount) + Number(this.state.promotionalSMSLotAmount) + Number(this.state.onsiteVisitCharge) + Number(additionalCashRegisterAmount) + Number(this.state.senderID ? this.state.senderIDAmount :  0)
          let taxAmount = Math.round((subTotal* 18)/100)
          let amount = subTotal + taxAmount
          this.setState({step:3, additionalStoreRate:additionalStoreRate, 
            additionalStoreAmount:additionalStoreAmount,
            additionalStoreDiscountPercentage:additionalStoreDiscountPercentage,
            additionalStoreDiscountAmount:additionalStoreDiscountAmount,
            additionalCashRegisterRate:additionalCashRegisterRate,
            additionalCashRegisterDiscount:additionalCashRegisterDiscount,
            additionalCashRegisterAmount:additionalCashRegisterAmount,
            subTotal:subTotal,
            taxAmount:taxAmount,
            amount:amount})
       }
    }

    onBackClicked(){
      let step = Number(this.state.step)
      step = step-1
      this.setState({step:step})      
    }

  render(){
    return (
      <div className="App">
        <br/>
        <a href="https://posible.in"><img  style={{width:"210px"}} src={ require('./logo.png')} /></a>
        <br/>
        <br/>
        <h3>Please refer new pricing on our website </h3>  
      </div>
        )
        }
    // return (
    //   <div className="App">
    //     <br/>
    //     <a href="https://posible.in"><img  style={{width:"210px"}} src={ require('./logo.png')} /></a>
    //     <h2>Please refer new pricing on website </h2>  
    //     <div className="py-5 text-center">
    //       <h2>Pricing</h2>
    //       {this.state.step ===1 && <p>Please select payment plan and features that you would like to use</p>}
    //       {this.state.step ===2 && <p>Please select below options</p>}
    //     </div>
    //   {this.state.step ===1 &&<div className="container" >
          
    //     <div className="input-group">
    //         <div className="input-group-prepend">
    //           <span className="input-group-text">Payment Plan</span>
    //         </div>
    //         <select type="select" class="form-control" 
    //         autoFocus={true}
    //         onChange={this.onPaymentPlanChanged} 
    //         value={this.state.paymentPlan}>
    //           <option value="Monthly">Monthly</option>
    //           <option value="Quaterly">Quaterly</option>
    //           <option value="Half Yearly">Half Yearly</option>
    //           <option value="Yearly">Yearly</option>
    //         </select>
    //       </div>
    //     <br/>
    //     <table className="table">
    //       <thead>
    //           <th>

    //           </th>
    //           <th style={{textAlign:"left"}}>
    //             Feature
    //           </th>
    //           <th>
    //             {this.state.paymentPlan} Price 
    //           </th>
              
    //       </thead>
    //     {this.state.features.map((m,index)=><tr>
    //       <td style={{width:"10px",verticalAlign:"top"}}> 
    //         <input type="checkbox" 
    //           checked={m.selected}
    //           onClick={()=> this.featureSelected(index, m.price)}/>
    //       </td>
    //       <td style={{textAlign:"left"}}>
    //         <div><h6>{m.text}</h6></div>
    //         <div style={{paddingBottom:"20px", fontSize:"14px"}}> <i>{m.description}</i></div>
    //       </td>
    //       <td style={{width:"100px",verticalAlign:"top"}}>
    //        <div><h6>{m.price}</h6></div>   
    //        <div style={{fontSize:"15px"}}>{m.priceAdditionalInfo}</div>   
    //        <div style={{color:"gray", fontSize:"14px"}}><strike>{Number(m.wasPrice) -1} p.a</strike></div>   
    //       </td>  
    //     </tr>)}
    //     <tr>
    //       <td>
    //       </td>
    //       <td style={{textAlign:"left"}}>
    //         <h6>Total</h6>
    //       </td>      
    //       <td>
    //           <h6>{this.state.featureDiscountedTotal}</h6>
    //       </td>
    //     </tr>
    //     <tr>
    //       <td colSpan="3">
    //       <button className="btn btn-md btn-success" onClick={this.onNextClicked} >Next</button>
    //       </td>
    //     </tr>
    //     </table>
    //   </div>}
    //   {this.state.step ===2 &&<div className="container" >
    //     <table className="table"><tr>
    //         <td style={{width:"10px",verticalAlign:"top"}}>
    //         </td>
    //         <td style={{textAlign:"left"}}>
    //           <div><h5>ADDITIONAL STORE</h5></div>
    //           <div style={{paddingBottom:"20px", fontSize:"14px"}}><i>If you have more than one store then please speciy the number of sotres. With every additional store one cash register is given</i></div>
    //         </td>
    //         <td style={{width:"70px",verticalAlign:"top"}}>
    //           <input type="number" 
    //           autoFocus={true}
    //           value={this.state.additionalStore} 
    //           onChange={(evt)=> {
    //             let additionalStore = evt.target.value<0 ? this.state.additionalCashRegister : evt.target.value
    //             this.onAdditionalStoreChange(additionalStore)
    //           }}
    //           style={{width:"100%"}}/>
    //         </td>  
    //     </tr>
    //     <tr>
    //         <td style={{width:"10px",verticalAlign:"top"}}>
    //         </td>
    //         <td style={{textAlign:"left"}}>
    //           <div><h5>ADDITIONAL CASH REGISTER</h5></div>     
    //           <div style={{paddingBottom:"20px", fontSize:"14px"}}><i>If you have more than one billing counter/till/cash register then please specify.</i></div>
    //         </td>
    //         <td style={{width:"70px",verticalAlign:"top"}}>
    //           <input type="number" 
    //           onChange={(evt)=> this.setState({additionalCashRegister: evt.target.value<0 ? this.state.additionalCashRegister : evt.target.value})}
    //           value={this.state.additionalCashRegister} 
    //           style={{width:"100%"}}/>
    //         </td>  
    //     </tr>
    //     <tr>
    //         <td style={{width:"10px",verticalAlign:"top"}}>
    //         </td>
    //         <td style={{textAlign:"left"}}>
    //           <div><h5>TRANSACTIONAL SMS</h5></div>    
    //           <div style={{paddingBottom:"20px", fontSize:"14px"}}><i>1 lot = 10,000 SMS<br/> 1 SMS lot @ 1500 INR<br/> {this.state.transactionalSMSLot} Lot X 10,000 = {this.state.transactionalSMSLot * 10000} SMS</i></div> 
    //         </td>
    //         <td style={{width:"70px",verticalAlign:"top"}}>
    //           <input type="number" 
    //           onChange={(evt)=> this.setState({transactionalSMSLot: evt.target.value<0 ? this.state.transactionalSMSLot : evt.target.value, transactionalSMSLotAmount: Number(evt.target.value) * Number(this.state.transactionalSMSLotRate)})}
    //           value={this.state.transactionalSMSLot} 
    //           style={{width:"100%"}}/>
    //         </td>  
    //     </tr>
    //     <tr>
    //         <td style={{width:"10px",verticalAlign:"top"}}>
    //         </td>
    //         <td style={{textAlign:"left"}}>
    //           <div><h5>PROMOTIONAL SMS</h5></div>    
    //           <div style={{paddingBottom:"20px", fontSize:"14px"}}><i>1 lot = 10,000 SMS<br/> 1 lot @ 1500 INR<br/> {this.state.promotionalSMSLot} Lot X 10,000 = {this.state.promotionalSMSLot * 10000} SMS</i></div> 
    //         </td>
    //         <td style={{width:"70px",verticalAlign:"top"}}>
    //           <input type="number" 
    //           onChange={(evt)=> this.setState({promotionalSMSLot: evt.target.value<0 ? this.state.promotionalSMSLot : evt.target.value, promotionalSMSLotAmount: Number(evt.target.value)* Number(this.state.promotionalSMSLotRate)})}
    //           value={this.state.promotionalSMSLot} 
    //           style={{width:"100%"}}/>
    //         </td>  
    //     </tr>
    //     <tr>
    //       <td style={{width:"10px",verticalAlign:"top"}}> 
    //           <input type="checkbox" 
    //             checked={this.state.onsiteVisit}
    //             onClick={()=> {
    //               let onsiteVisit = !this.state.onsiteVisit
    //               this.setState({onsiteVisit:onsiteVisit})
    //               this.computeOnsiteTraining(Number(this.state.additionalStore),onsiteVisit)}
    //             }/>
    //         </td>
    //         <td style={{textAlign:"left"}}>
    //           <div><h5>ONSITE TRAINING</h5></div>
    //           <div style={{paddingBottom:"20px", fontSize:"14px"}}> <i>Per visit @3000<br/>{this.state.onsiteVisitCharge > 0 && <div>{1+ Number(this.state.additionalStore)} Store(s) X 3000 = {this.state.onsiteVisitCharge}</div>}</i></div>
    //         </td>
    //         <td style={{width:"70px",verticalAlign:"top"}}>
    //         <div ><h5>{this.state.onsiteVisitCharge}</h5></div>    
    //         </td> 
    //     </tr>
    //     <tr>
    //       <td style={{width:"10px",verticalAlign:"top"}}> 
    //           <input type="checkbox" 
    //             checked={this.state.senderID}
    //             onClick={()=> this.setState({senderID:!this.state.senderID})}
    //             />
    //         </td>
    //         <td style={{textAlign:"left"}}>
    //           <div><h5>SENDER ID</h5></div>
    //           <div style={{paddingBottom:"20px", fontSize:"14px"}}> <i>By using a Sender ID, your customers will immediately recognize who the SMS text message is from and increase your brand recall.</i></div>
    //         </td>
    //         <td style={{width:"70px",verticalAlign:"top"}}>
    //         <div ><h5>{this.state.senderIDAmount}</h5></div>    
    //         </td> 
    //     </tr>
    //     <tr>
    //       <td colSpan="3">
    //       <button className="btn btn-md btn-default" onClick={this.onBackClicked} >Back</button>
    //       <button className="btn btn-md btn-success" onClick={this.onNextClicked} >Next</button>
    //       </td>
    //     </tr>
    //     </table>
    //   </div>}
    //     {this.state.step===3 && <div className="container" >
    //         <table  className="table">
    //           <thead>
    //               <th style={{textAlign:"left"}}>
    //                   Description
    //               </th>
    //               <th>
    //                   Qty
    //               </th>
    //               <th>
    //                   Rate
    //               </th>
    //               <th>
    //                   Dis
    //               </th>
    //               <th>
    //                   Amt
    //               </th>
    //           </thead>
    //           <tbody>
    //               <tr>
    //                   <td style={{textAlign:"left"}}>
    //                       <b>Main store</b>
    //                       <div>{this.state.paymentPlan} payment plan</div>
    //                       {this.state.features.filter(f=> f.selected).map((m,index)=><div>{m.text} @ {m.price}</div>)}
    //                   </td>
    //                   <td>
    //                     {1}
    //                   </td>
    //                   <td>
    //                     {this.state.featureTotal}
    //                   </td>
    //                   <td>
    //                     {this.state.mainStoreDiscountAmount}
    //                     <div>{this.state.mainStoreDiscountPercentage} %</div>
    //                   </td>
    //                   <td>
    //                     {this.state.mainStoreAmount}
    //                   </td>
    //               </tr>
    //               {this.state.additionalStore > 0 &&<tr>
    //                   <td style={{textAlign:"left"}}> 
    //                       <b>Additional Store</b>
    //                       <div>{this.state.paymentPlan} payment plan</div>
    //                   </td>
    //                   <td>
    //                       {this.state.additionalStore}
    //                   </td>
    //                   <td>
    //                     {this.state.additionalStoreRate}
    //                   </td>
    //                   <td>
    //                     {this.state.additionalStoreDiscountAmount}
    //                     <div>{this.state.additionalStoreDiscountPercentage}%</div>
    //                   </td>
    //                   <td>
    //                     {this.state.additionalStoreAmount}
    //                   </td>
    //               </tr>}
    //               {this.state.additionalCashRegister > 0 &&<tr>
    //                   <td style={{textAlign:"left"}}>
    //                       <b>Additional Cash Register</b>
    //                   </td>
    //                   <td>
    //                       {this.state.additionalCashRegister}
    //                   </td>
    //                   <td>
    //                     {this.state.additionalCashRegisterRate}
    //                   </td>
    //                   <td>
    //                     {this.state.additionalCashRegisterDiscount}
                        
    //                   </td>
    //                   <td>
    //                     {this.state.additionalCashRegisterAmount}
    //                   </td>
    //               </tr>}
    //               {this.state.transactionalSMSLot > 0 &&<tr>
    //                   <td style={{textAlign:"left"}}>
    //                       <b>Transactional SMS</b>
    //                       <div> {this.state.transactionalSMSLot} Lot X 10,000 = {this.state.transactionalSMSLot * 10000} SMS</div>
    //                   </td>
    //                   <td>
    //                       {this.state.transactionalSMSLot}
    //                   </td>
    //                   <td>
    //                     {this.state.transactionalSMSLotRate}
    //                   </td>
    //                   <td>
    //                     {0}
    //                   </td>
    //                   <td>
    //                     {this.state.transactionalSMSLotAmount}
    //                   </td>
    //               </tr>}
    //               {this.state.promotionalSMSLot > 0 &&<tr>
    //                   <td style={{textAlign:"left"}}>
    //                       <b>Promotional SMS</b>
    //                       <div> {this.state.promotionalSMSLot} Lot X 10,000 = {this.state.promotionalSMSLot * 10000} SMS</div>
    //                   </td>
    //                   <td>
    //                       {this.state.promotionalSMSLot}
    //                   </td>
    //                   <td>
    //                     {this.state.promotionalSMSLotRate}
    //                   </td>
    //                   <td>
    //                     {0}
    //                   </td>
    //                   <td>
    //                     {this.state.promotionalSMSLotAmount}
    //                   </td>
    //               </tr>}
    //               {this.state.senderID > 0 &&<tr>
    //                   <td style={{textAlign:"left"}}>
    //                       <b>Sender ID</b>
    //                   </td>
    //                   <td>
    //                       {1}
    //                   </td>
    //                   <td>
    //                     {this.state.senderIDAmount}
    //                   </td>
    //                   <td>
    //                     {0}
    //                   </td>
    //                   <td>
    //                     {this.state.senderIDAmount}
    //                   </td>
    //               </tr>}
    //               {this.state.onsiteVisitCharge > 0 &&<tr>
    //                   <td style={{textAlign:"left"}}>
    //                       <b>Onsite Visit</b>
    //                   </td>
    //                   <td>
    //                       {Number(this.state.additionalStore + 1)}
    //                   </td>
    //                   <td>
    //                     {3000}
    //                   </td>
    //                   <td>
    //                     {0}
    //                   </td>
    //                   <td>
    //                     {this.state.onsiteVisitCharge}
    //                   </td>
    //               </tr>}
    //               <tr>
    //                   <td colSpan="4" style={{textAlign:"right"}}>
    //                     Sub Total
    //                   </td>
    //                   <td>
    //                       {this.state.subTotal}
    //                   </td>
    //               </tr>
    //               <tr>
    //                   <td colSpan="4" style={{textAlign:"right"}}>
    //                     GST 18%
    //                   </td>
    //                   <td>
    //                     {this.state.taxAmount}
    //                   </td>
    //               </tr>
    //               <tr>
    //                   <td colSpan="4" style={{textAlign:"right"}}>
    //                     <b>Amount</b>
    //                   </td>
    //                   <td>
    //                     <b>{this.state.amount}</b>
    //                   </td>
    //               </tr>
    //               <tr>
    //               <td colSpan="5">
    //               <button className="btn btn-md btn-default" onClick={this.onBackClicked} >Back</button>
    //               </td>
    //             </tr>
    //           </tbody>
    //         </table>
    //     </div>}
    //   </div>
    // );
  // }
}

export default App;
